var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__case"},[_c('y-list-page',{ref:"listPageRef",attrs:{"pro-form-style":{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },"form-style":{ textAlign: 'right' },"inline":"","label-width":"100px","model":_vm.model,"fields":_vm.fields,"submitter":{
      submitText: '搜索',
      resetButtonProps: {
        style: {
          display: 'none'
        }
      }
    },"columns":_vm.columns,"fetch":_vm.fetchFn},scopedSlots:_vm._u([{key:"form-before-addon",fn:function(){return [_c('div',{staticStyle:{"display":"flex"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.downloadList}},[_vm._v(" 批量导出作品列表 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.downloadImgs}},[_vm._v(" 批量导出作品图片 ")]),_c('el-upload',{staticStyle:{"display":"inline-block","margin-left":"10px"},attrs:{"headers":_vm.headers,"action":_vm.action,"show-file-list":false,"on-success":_vm.uploadSuccess,"on-progress":_vm.uploadProcess,"on-error":_vm.uploadError}},[_c('el-button',{attrs:{"loading":_vm.uploadLoading,"type":"primary"}},[_vm._v(" 批量导入作品 ")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }