<template>
  <div class="page__case">
    <y-list-page
      ref="listPageRef"
      :pro-form-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }"
      :form-style="{ textAlign: 'right' }"
      inline
      label-width="100px"
      :model="model"
      :fields="fields"
      :submitter="{
        submitText: '搜索',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      :columns="columns"
      :fetch="fetchFn"
    >
      <template #form-before-addon>
        <div style="display: flex">
          <el-button @click="downloadList" type="primary">
            批量导出作品列表
          </el-button>
          <el-button @click="downloadImgs" type="primary">
            批量导出作品图片
          </el-button>
          <el-upload
            :headers="headers"
            :action="action"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :on-progress="uploadProcess"
            :on-error="uploadError"
            style="display: inline-block; margin-left: 10px"
          >
            <el-button :loading="uploadLoading" type="primary">
              批量导入作品
            </el-button>
          </el-upload>
        </div>
      </template>
    </y-list-page>
  </div>
</template>

<script>
import localStore from 'store'
import { baseUrl } from '@/config/env'

export default {
  name: 'Case',
  data() {
    return {
      action: baseUrl + '/import/uploadExcel',
      uploadLoading: false,
      model: {
        keyword: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '关键词',
            prop: 'keyword'
          }
        }
      ],
      columns: [
        {
          label: '#ID',
          prop: 'id',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '作品名称',
          prop: 'name',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '作品图片',
          prop: 'imgUrl',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            // return <el-avatar shape="square" size={100} src={v}></el-avatar>
            return <img src={v} style="width: 200px"></img>
          }
        },
        {
          label: '团队成员信息',
          prop: 'teamMembers',
          render(h, v) {
            if (v == undefined || v.length === 0) {
              return '-'
            }
            return (
              <div>
                {v.map((item, i) => {
                  return (
                    <div key={i}>
                      <p>姓名: {item.name}</p>
                      <p>电话: {item.contact}</p>
                      <p>学院: {item.school}</p>
                      {i !== v.length - 1 && <el-divider></el-divider>}
                    </div>
                  )
                })}
              </div>
            )
          }
        },
        {
          label: '创建时间',
          prop: 'createTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        }
      ]
    }
  },
  computed: {
    headers() {
      return {
        token: localStore.get('feisi-user2')?.token
      }
    }
  },
  methods: {
    fetchFn({ currentPage, ...data }) {
      return this.$serve
        .worksList({
          data: {
            ...data,
            pageIndex: currentPage
          }
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return {
            ...res,
            page: res.pageIndex
          }
        })
    },
    downloadList() {
      this.$download({
        fn: 'exportWorks',
        filename: '批量导出作品列表.xls'
      })
    },
    downloadImgs() {
      this.$download({
        fn: 'exportWorksPic',
        filename: '批量导出作品图片.zip'
      })
    },
    uploadProcess() {
      this.uploadLoading = true
    },
    uploadError() {
      this.uploadLoading = false
      this.$message.success('导入失败')
    },
    uploadSuccess() {
      this.uploadLoading = false
      this.$message.success('导入成功')
      this.$refs.listPageRef.refresh()
    }
  }
}
</script>
